import { RATE_FROM_PARAM, RATE_TO_PARAM } from "@/js/constants/urlParams.js";
import { currencyDropdowns } from "./components/currencyDropdows.js";
import { setCurrencyInfo } from "./components/currencyInfo.js";
import { eChart } from "./components/eChart.js";
import { setAveragePrice } from "./components/setAveragePrice.js";
import { setCurrencyValues } from "./components/setCurrencyValues.js";
import { convert } from "./converter.js";
import { updatePageSearchParams } from "./utilles/updatePageSearchParams";

export function currencyMain(rates, initial = {}) {
  let updateChart = null;
  let initialized = false;

  function onCurrencyChange(from, to) {
    const currentRateData = {
      from: { currency: from, value: null },
      to: { currency: to, value: convert(rates, { from, to }) },
    };

    const data = rates.map((_, idx) => {
      return convert(rates, { from, to }, idx);
    });

    const chartData = data.map((item, idx) => {
      return [rates[idx]?.date, item];
    });

    setCurrencyInfo(currentRateData);
    setAveragePrice(data, to);
    setCurrencyValues(currentRateData);

    if (!updateChart && data) {
      updateChart = eChart(chartData)?.onUpdate;
    } else {
      updateChart(chartData);
    }

    if (initialized) {
      updatePageSearchParams(
        { key: RATE_FROM_PARAM, value: from.toLowerCase() },
        { key: RATE_TO_PARAM, value: to.toLowerCase() }
      );
    }

    initialized = true;
  }

  currencyDropdowns({ rates }, onCurrencyChange, initial);
}
