const sinusoidalIn = (k) => 1 - Math.cos((k * Math.PI) / 2);
export const eChartOptions = (xAxisData, yAxisData, { echarts }) => {
  const isMobile = window.matchMedia("(max-width: 769px)").matches;

  return {
    color: ["#BA3BFA", "#4865FF", "#03DBA6"],
    textStyle: {
      fontFamily: "gilroy",
      fontSize: 10,
    },

    grid: {
      top: isMobile ? 80 : 90,
      left: isMobile ? 40 : 85,
      bottom: isMobile ? 25 : 30,
      right: 40,
    },

    tooltip: {
      trigger: "item",
      position: "top",
      axisPointer: { type: "none", show: false },
      className: "js-chart-tooltip",
      renderMode: "html",
      backgroundColor: "#3282FF",
      borderColor: "#3282FF",
      textStyle: { color: "#fff" },
      formatter({ dataIndex, data }) {
        const date = xAxisData[dataIndex];

        return `
          <p class="js-chart-tooltip__value"> <b>${data}</b></p>
          <p class="js-chart-tooltip__date">
            ${date}
          </p>
        `;
      },
    },

    xAxis: {
      show: false,
      data: xAxisData,
      axisTick: { show: true },
    },

    yAxis: {
      splitNumber: 2,
      scale: true,
      offset: isMobile ? 0 : 25,
      axisLabel: { show: !isMobile },
    },

    series: [
      {
        type: "line",
        data: yAxisData,
        smooth: true,
        animationDuration: 2000,
        animationEasing: sinusoidalIn,
        symbolSize: 28,
        showSymbol: true,
        showAllSymbol: true,
        symbol:
          "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABICAYAAABY88MAAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQgSURBVHgB7ZvPixxVEMe/7812Z8xuYPIPzI4XD6Kb3YsoEt05CMFL9D9IUM/mohIlhCWJ0YOQPQkiRG8e9aBEPOyPCB487GpEIZf07D9gSzTpdE/3s14n0Wj8MfOm3usm1AeWWXp6Z3q/XfWqXlU1IAiCIAiCIAiCIAiCIAhTo9A0vV5vLhsvqw6OqkoN6MiyUejRhfXs2wZIFVRijEmVMltVhc1x9usmGqYx4ea6C6tWLBh17K5Ik2LFpJdPUVTrRfHbLhoguHC1YEqdVgqrYMAYbBamOI4sSxCQcMKRS0ZFdVoZcwIeMEZdKPbpNaRpigCEEa7bG8Sq3KBvG8AvSV4VwxDWp+GbaH450uVOANEsg1hHGxF9Jzzj1+KsaJHemHbxZyA1RTX0GTj8CRfOPf+NlNx2xZfb+hGOAkF8K5h7/hdJHndWfASMDjwQdeLzdEeOoHl6eoxuNb71JZjhtzjrorq8hhZRVWbIvdtgj6qRKi+iZdiEG8ywWlw0P7+sjN5BC+G2Ol6LM/pVtBTaF78ARngtbv+BnzlytsXFRSz2+/Xvo9EIo709MJBShH2YK8KyWVy9eZ9RtGcOH8ZXl77A1R+v0Ovn9c/Vn36ojx1aehwzUpevwARbOjIXx8fIgFfhyKk3T+LDD94na+vf95499srLL9EiD2xf/hrOaPxSFfklMMDmqtFDBzZcS0VLS0v49pvJBHnuyPPu4hl8lt+8zrLW8QUHZZzd9L1335n43FNvnYQzCofABJ/FOQYGGwTsOjYNjzz6GAUNp4CR5jeuHwQDbBbnGhhsBJ36b/p9OMJWpfFfj3tAYRPuTgNlakZ7I0zLDHkdW5WEz1WNo3C0Vm1vX574/C2KqI7rG91clYAJzqjqXG098/b5ic997fU34Iyp2mdx1ESe3ufuYPOyM+f+Xzwr8HffX4ErlGdugQk+4UpqEM/AWRLliaeeJre9P7m17mkT37PnJrfMf8JOAYAJvk2+7Zvm5TWeTX7/z00+BQLXNe1vsOVwFuZ63MIFZVRbS0sfkXDHwQRrHjeru/qE2oXrYIRVOFthtbMcaBnGmE3uHiv7zoEucg0to2PGbC76x2eCmWqcJzqODyqoJ9ECjDLr+c0bn4CZB74hvZ8a0qmHhrS3EYgu9VdLXe40MDdyl0RXxTDzNALhrTqSZWmCohoaxo31pJjbQzcvZh7HvbyWlepIRuLRf5IgEPWN8jypZAkyWGjdtgo0WOjTPe8lSCHTum2+r7NiIxw8YT/bBoIs0Cxw8OHpOmio8UWl3FuJ92KTW0q610KP8Dc2rm/HTVWkT9CadNRlXF8r83FJW7ymnnlo/gER3J4C0BqrZDnP0hWRiGrwlwdE6uqy2VUaIyvWQnduNw00XS4IgiAIgiAIgiAIgiAIjPwOFvqgR3waI1MAAAAASUVORK5CYII=",
        lineStyle: {
          width: 4,
          cap: "round",
          shadowColor: "rgba(0, 0, 0, 0.12)",
          shadowOffsetX: 0,
          shadowOffsetY: 21,
          shadowBlur: 10,
          color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 0,
              color: "#BA3BFA",
            },
            {
              offset: 0.5,
              color: "#4865FF",
            },
            {
              offset: 1,
              color: "#03DBA6",
            },
          ]),
        },
      },
    ],
  };
};
