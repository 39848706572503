import $ from "jquery";
import { ACTIVE_CLASSNAME } from "@/js/constants/constansts";
const TRIGGER_SELECTOR = ".app-dropdown-trigger";

function selectOption({ $trigger, $option }, onSelectCb) {
  const $opt = $($option);
  const attrs = $opt.get(0).attributes;
  const clonnedOpt = $opt.html();

  const attrStr = Array.from(attrs).reduce((acc, attr) => {
    const { name, value } = attr;
    acc += ` ${name}="${value}"`;

    return acc;
  }, "");

  const triggerContent = `
    <div ${attrStr}> ${clonnedOpt} </div>
  `;

  $trigger.find("[data-trigger-content]").html(triggerContent);

  onSelectCb();
}

function isSm() {
  return window.matchMedia("(max-width: 575px)").matches;
}

export function initDropdown() {
  const $dropdowns = $(".app-dropdown");
  const $triggers = $(TRIGGER_SELECTOR);

  let scrollLocked = false;
  let resizeTimeout = null;
  let languageSelectOpened = false;

  $dropdowns.on("click", (evt) => {
    evt.stopPropagation();
  });

  $(".language-select__trigger").on("click", () => {
    languageSelectOpened = !languageSelectOpened;

    if (isSm()) {
      if (!scrollLocked) {
        $(window).scrollTop(0);
        $("body").css("overflow", "hidden");
        scrollLocked = true;
      } else {
        $("body").css("overflow", "auto");
        scrollLocked = false;
      }
    }
  });

  $(window).on("resize", () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      if (scrollLocked && !isSm()) {
        $("body").css("overflow", "auto");
        scrollLocked = false;
      } else if (languageSelectOpened) {
        $(window).scrollTop(0);
        $("body").css("overflow", "hidden");
        scrollLocked = true;
      }
    }, 200);
  });

  $dropdowns.each(function () {
    const $self = $(this);
    const { inputId } = $self.data();

    const $sensor = $(`#${inputId}`);
    const $options = $self.find("li").toArray();
    const $trigger = $self.siblings(TRIGGER_SELECTOR);

    let selectedCurrency = null;

    $options.forEach((opt) => {
      const $option = $(opt);
      const { value } = $option.data();

      $option.on("click", () => {
        selectOption({ $trigger, $option }, () => {
          close();

          $sensor.val(value);
          $sensor.trigger("change");

          selectedCurrency = value;
        });
      });
    });

    $sensor.on("change", ({ target }) => {
      const currency = $sensor.val();
      const $options = $(target).siblings(".app-dropdown").find("li").toArray();

      if (currency !== selectedCurrency) {
        const $selectedOption = $options.find((option) => {
          return $(option).data("value") === currency;
        });

        if (!$selectedOption) {
          throw new Error(`Currency ${currency} do not exist in dropdown`);
        }

        selectOption({ $trigger, $option: $selectedOption }, () => {
          close();
          selectedCurrency = currency;
        });
      }
    });
  });

  $triggers.on("click", function (evt) {
    evt.stopPropagation();

    const { target } = $(this).data();

    $(target).toggleClass(ACTIVE_CLASSNAME);
    $(this).toggleClass(ACTIVE_CLASSNAME);
  });

  function close() {
    $dropdowns.removeClass(ACTIVE_CLASSNAME);
    $triggers.removeClass(ACTIVE_CLASSNAME);
  }

  $(document).on("click", close);
}
