import { RATE_FROM_PARAM, RATE_TO_PARAM } from "@/js/constants/urlParams.js";
import { getAcceptecCurrencies } from "./getAcceptecCurrencies.js";
import { getPageSearchParams } from "./getPageSearchParams.js";

export function getInitialRateParams() {
  const aceeptedRates = getAcceptecCurrencies();

  const fromRatesParams = getPageSearchParams(
    [RATE_FROM_PARAM],
    aceeptedRates[RATE_FROM_PARAM]
  );

  const toRatesParams = getPageSearchParams(
    [RATE_TO_PARAM],
    aceeptedRates[RATE_TO_PARAM]
  );

  return {
    ...fromRatesParams,
    ...toRatesParams,
  };
}
