export function convert(rates, { from, to }, idx = rates.length - 1) {
  const { base, rates: courses } = rates[idx] || {};

  if (!courses) {
    return null;
  }

  const baseRate = courses[base];
  const fromRate = baseRate / courses[from];
  const toRate = (courses[to] * fromRate).toFixed(5);

  return toRate;
}
