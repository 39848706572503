import $ from "jquery";

const $fromCurrency = $("[data-from-currency]");
const $toValue = $("[data-to-value]");
const $toCurrency = $("[data-to-currency]");

export function setCurrencyValues({ from, to }) {
  $fromCurrency.text(from.currency);
  $toValue.text(parseFloat(to.value).toFixed(2));
  $toCurrency.text(to.currency);
}
