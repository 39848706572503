import $ from "jquery";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { SVGRenderer } from "echarts/renderers";
import { eChartOptions } from "./eChartConf.js";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  SVGRenderer,
  UniversalTransition,
]);

function prepareChartData(data) {
  const dates = [];
  const rates = [];

  data.forEach(([date, rate]) => {
    dates.push(date.toString());
    rates.push(Number(Number(rate).toFixed(2)));
  });

  return { dates, rates };
}

let chart;

$("window").on("resize", () => {
  if (chart) {
    chart.resize();
  }
});

export function eChart(data) {
  const { dates, rates } = prepareChartData(data);
  const node = document.getElementById("chart");

  if (!node) {
    return;
  }

  chart = echarts.init(node);
  const options = eChartOptions(dates, rates, { echarts });
  chart.setOption(options);

  return {
    onUpdate(data) {
      const { dates, rates } = prepareChartData(data);
      const options = eChartOptions(dates, rates, { echarts });

      chart.dispose();
      chart = echarts.init(node);

      chart.setOption(options);
    },
  };
}
