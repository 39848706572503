import $ from "jquery";
import MicroModal from "micromodal";

const triggers = [
  ["show", "show"],
  ["close", "close"],
];

export function initModal() {
  MicroModal.init();

  window.modal = MicroModal;

  triggers.forEach(([classModifier, action]) => {
    $(`.${classModifier}-modal`).each(function () {
      const $trigger = $(this);
      const { target } = $trigger.data();

      if (!target) {
        return;
      }

      $trigger.on("click", (evt) => {
        evt.preventDefault();
        MicroModal[action](target);
      });
    });
  });
}
