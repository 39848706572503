export function updatePageSearchParams(...params) {
  if (!params) {
    return;
  }

  let url =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?";

  params.forEach(({ key, value }, idx) => {
    let divider = "&";
    if (idx === 0) {
      divider = "";
    }

    url += `${divider}${key}=${value}`;
  });

  window.history.pushState({ path: url }, "", url);
}
