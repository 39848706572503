import $ from "jquery";
import { regexps } from "./regexps.js";

function validate({ target }) {
  const $field = $(target);
  const value = $field.val();
  const { type, required, requiredWhen } = $field.data();

  if (required !== undefined) {
    const requiredMsg = $field.data("msg-required") || null;

    if (requiredWhen !== undefined) {
      const equalChecked = $(requiredWhen).prop("checked");
      let error = false;

      if (equalChecked) {
        const val = value.trim() || "";

        if (val.length < 1 || val === ".") {
          error = true;
        } else {
          error = Number(val) < 0.1;
        }
      }

      return {
        msg: !error ? requiredMsg : null,
        error,
      };
    }

    if (value.trim().length < 1) {
      return {
        msg: requiredMsg,
        error: true,
      };
    }
  }

  const reg = regexps[type];

  if (reg) {
    const typeMsg = $field.data(`msg-${type}`) || null;

    let msg = typeMsg;
    let error = !reg.test(value);

    if (type === "email") {
      const at = value.includes("@");

      if (!at) {
        msg = $field.data(`msg-at`) || null;
      }
    } else if (type === "float" && !error) {
      if (value[0] === 0 && value[1] !== ".") {
        error = true;
      } else {
        error = parseFloat(value) < 0.1;
      }
    }

    return {
      error,
      msg,
    };
  }

  return {
    error: false,
  };
}

export function mainValidator() {
  $("form").each(function () {
    const $form = $(this);
    const $fields = $form
      .find("input")
      .toArray()
      .filter((field) => field.dataset.required !== undefined);

    const $submitBtn = $form.find("button[type='submit']");

    function onFormError() {
      $submitBtn.prop("disabled", true);
    }

    function onFormValid() {
      $submitBtn.prop("disabled", false);
    }

    const fieldsModel = {
      fields: $fields.reduce((acc, field) => {
        const type = field.getAttribute("type");
        const { error: isInvalid } = validate({ target: field });

        if (type !== "radio" && type !== "checkbox") {
          const id = field.getAttribute("id");
          const $node = $(field);

          acc[id] = {
            valid: !isInvalid,
            node: $node,
            parent: $node.parents(".app-input"),
            errEl: $(`[data-err-for="${id}"]`),
            touched: false,
          };
        }

        return acc;
      }, {}),

      isValid() {
        return Object.values(this.fields).every(({ valid }) => valid);
      },

      getField(id) {
        return this.fields[id];
      },

      setValidation(inputId, status) {
        this.getField(inputId).valid = status;
      },

      showError(inputId, errMsg) {
        const input = this.getField(inputId);
        const { node, parent, touched, errEl } = input;

        if (touched) {
          node.addClass("js-error");
          parent.addClass("js-error");

          if (errMsg) {
            errEl.show();
            errEl.text(errMsg);
          }
        }
      },

      setError(inputId, errMsg, { touched } = {}) {
        const input = this.getField(inputId);

        if (touched !== undefined) {
          input.touched = true;
        }

        input.valid = false;

        this.showError(inputId, errMsg);

        onFormError();
      },

      removeError(inputId, { touched } = {}) {
        const input = this.getField(inputId);
        const { node, errEl, parent } = input;

        if (touched !== undefined) {
          input.touched = true;
        }

        input.valid = true;

        node.removeClass("js-error");
        parent.removeClass("js-error");
        errEl.hide();
        errEl.text("");

        if (this.isValid()) {
          onFormValid();
        }
      },
    };

    $form
      .find("input")
      .toArray()
      .forEach((field) => {
        console.log(field);
        if (field.type === "checkbox" || field.type === "radio") {
          $(field).on("change", () => {
            Object.values(fieldsModel.fields).forEach(({ node }) => {
              $(node).trigger("change", [true]);
            });
          });
        }
      });

    Object.entries(fieldsModel.fields).forEach(([id, { node: $field }]) => {
      $field.on("input", (evt) => {
        const { error, msg } = validate(evt);

        if (error) {
          fieldsModel.setError(id, msg);
        } else {
          fieldsModel.removeError(id);
        }
      });

      $field.on("change", (evt, synthetic) => {
        const { error, msg } = validate(evt);

        const conf = {};

        // synthetic = true, when radio was changed
        if (!synthetic) {
          conf.touched = true;
        }

        if (error) {
          fieldsModel.setError(id, msg, conf);
        } else {
          fieldsModel.removeError(id, conf);
        }
      });
    });
  });
}
