import $ from "jquery";
import { ACTIVE_CLASSNAME } from "@/js/constants/constansts";

export function initMenu() {
  const $menu = $(".page-header__nav");
  const $submenu = $(".page-header__submenu");
  const $menuToggle = $("[data-action='menu:toggle']");

  $menuToggle.on("click", () => {
    $menu.toggleClass(ACTIVE_CLASSNAME);
    $menuToggle.toggleClass(ACTIVE_CLASSNAME);
  });

  $submenu.each(function () {
    const $submenu_item = $(this);
    const $trigger = $submenu_item.parent(".submenu-trigger");
    let timeout = null;
    let opened = false;

    function show() {
      $trigger.addClass(ACTIVE_CLASSNAME);
      $submenu_item.slideDown("fast");
      opened = true;
    }

    function hide() {
      $trigger.removeClass(ACTIVE_CLASSNAME);
      $submenu_item.slideUp("fast");
      opened = false;
    }

    const DELAY = 200;

    $trigger.hover(
      () => {
        let delay = timeout ? DELAY : 0;
        clearTimeout(timeout);
        timeout = setTimeout(show, delay);
      },
      () => {
        let delay = timeout ? DELAY : 0;
        clearTimeout(timeout);
        timeout = setTimeout(hide, delay);
      }
    );

    $trigger.children("a").on("click", () => {
      if (window.matchMedia("(max-width: 992px)").matches) {
        clearTimeout(timeout);
        let delay = timeout ? DELAY : 0;

        timeout = setTimeout(opened ? hide : show, delay);
      }
    });
  });
}
