import $ from "jquery";
import { RATE_FROM_PARAM, RATE_TO_PARAM } from "@/js/constants/urlParams.js";

export function getAcceptecCurrencies() {
  const acceptedRates = {
    [RATE_FROM_PARAM]: [],
    [RATE_TO_PARAM]: [],
  };

  const dropdowns = [
    [RATE_FROM_PARAM, "#currency-selector__from"],
    [RATE_TO_PARAM, "#currency-selector__to"],
  ];

  dropdowns.forEach(([key, selector]) => {
    const $options = $(selector).find("li").toArray();

    acceptedRates[key] = $options.map(
      ($opt) => $opt.dataset.value?.toLowerCase() || null
    );
  });

  return acceptedRates;
}
