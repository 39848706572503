import $ from "jquery";
import Inputmask from "inputmask";

function amountMask() {
  $("[data-type='float']").each(function () {
    const mask = new Inputmask({
      mask: "9[9][9][.]9[9][9]",
      placeholder: "",
      showMaskOnFocus: false,
      showMaskOnHover: false,
      positionCaretOnClick: "radixFocus",
    });

    mask.mask(this);
  });
}

export function maskFields() {
  amountMask();
}
