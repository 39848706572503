import $ from "jquery";
import { ACTIVE_CLASSNAME } from "@/js/constants/constansts";

export function alertsHandler() {
  const $alerts = $("[data-alert]");

  $alerts.toArray().forEach((alert) => {
    const $alert = $(alert);
    const $switch = $alert.find("input[type='checkbox']");

    function toggle() {
      $alert.toggleClass(ACTIVE_CLASSNAME, $switch.prop("checked"));
    }

    toggle();
    $switch.on("change", toggle);
  });
}
