import "@/scss/app.scss";
import $ from "jquery";

// Components
import { initMenu } from "@/js/modules/menu";
import { initDropdown } from "@/js/modules/dropdown";
import { currencyMain } from "@/js/modules/currency/currencyMain.js";
// import { rates } from "@/js/data/currencies";
import { mainValidator } from "./modules/forms/validator.js";
import { customScrollbar } from "./modules/customScrollbar.js";
import { initModal } from "./modules/modal.js";
import { getInitialRateParams } from "./modules/currency/utilles/getInitialRateParams.js";
import { initiTimer } from "./modules/timer/initiTimer.js";
import { alertsHandler } from "@/js/modules/currency/alertsHandler";
import { maskFields } from "@/js/modules/forms/mask";

//  Use this file as endpoint.
//  Only for connect modules.

// window.rates = rates;

$(document).ready(function () {
  const rates = window.rates || [];
  initMenu();
  alertsHandler();
  initiTimer();
  initDropdown();
  currencyMain(rates, getInitialRateParams());
  mainValidator();
  customScrollbar();
  initModal();
  maskFields();
});
