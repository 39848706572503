import $ from "jquery";
import { RATE_FROM_PARAM, RATE_TO_PARAM } from "@/js/constants/urlParams.js";

export function currencyDropdowns(_, onChangeCb = () => {}, initial = {}) {
  const $currencyFrom = $("#currency-selector__from");
  const $fromSensor = $(`#${$currencyFrom.data("input-id")}`);

  const $currencyTo = $("#currency-selector__to");
  const $toSensor = $(`#${$currencyTo.data("input-id")}`);
  const $sensors = [$fromSensor, $toSensor];
  const initialFrom =
    initial[RATE_FROM_PARAM]?.toUpperCase() ||
    $currencyFrom.data("initial-currency");

  const initialTo =
    initial[RATE_TO_PARAM]?.toUpperCase() ||
    $currencyTo.data("initial-currency");

  const currencies = [initialFrom, initialTo];

  // SET INITIAL VALUES
  $fromSensor.val(initialFrom);
  $toSensor.val(initialTo);

  currencies.forEach((currency, idx) => {
    $sensors[idx].val(currency);
    $sensors[idx].trigger("change");
  });

  onChangeCb(...currencies);

  // ADD LISTENERS
  $sensors.forEach(($sensor, idx) => {
    $sensor.on("change", ({ target }) => {
      if (currencies[idx] !== target.value) {
        currencies[idx] = target.value;
        onChangeCb(...currencies);
      }
    });
  });
}
