import { timeEnum } from "./timeEnum.js";

const secondsInMinute = 60;
const secondsInHours = 3600;
const secondsInDay = 86400;

const { seconds, minutes, hours, days } = timeEnum;

export function timeConverter(secondsLeft) {
  if (secondsLeft < secondsInMinute) {
    return {
      units: seconds,
      value: secondsLeft,
    };
  }

  if (secondsLeft < secondsInHours) {
    return {
      units: minutes,
      value: parseInt(secondsLeft / secondsInMinute),
    };
  }

  if (secondsLeft < secondsInDay) {
    return {
      units: hours,
      value: parseInt(secondsLeft / secondsInHours),
    };
  }

  return {
    units: days,
    value: parseInt(secondsLeft / secondsInDay),
  };
}
