export function getPageSearchParams(keys = [], acceptedValues) {
  const url = new URL(window.location);
  const params = {};

  keys.forEach((key) => {
    const paramValue = url.searchParams.get(key);

    if (paramValue) {
      params[key] = paramValue;
    }
  });

  if (!acceptedValues) {
    return params;
  }

  return Object.fromEntries(
    Object.entries(params).filter((param) => {
      const value = param[1];
      return acceptedValues.includes(value);
    })
  );
}
