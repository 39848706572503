import $ from "jquery";
import { timeConverter } from "./timeConverter.js";
import { timeEnum } from "./timeEnum.js";

const STEP = 1000;

const { seconds, minutes, hours, days } = timeEnum;

function timer(initial, translates, tickHandler) {
  let time = new Date(Date.now()).getTime();
  let secondsLeft = 0;

  setInterval(() => {
    time += STEP;
    secondsLeft = (time - initial) / STEP;

    const { units, value } = timeConverter(parseInt(secondsLeft));
    tickHandler({ units: translates[units], value });
  }, STEP);
}

export function initiTimer() {
  const $nodes = $("[data-timer]");

  $nodes.each(function () {
    const $node = $(this);
    const $timeNode = $node.find(".time");

    const {
      initial: dataInitial,
      secondsWord,
      minutesWord,
      hoursWord,
      daysWord,
    } = $node.data();

    const initial =
      dataInitial === -1
        ? new Date(Date.now()).getTime()
        : new Date(dataInitial).getTime() * 1000;

    timer(
      initial,
      {
        [seconds]: secondsWord,
        [minutes]: minutesWord,
        [hours]: hoursWord,
        [days]: daysWord,
      },
      ({ value, units }) => {
        $timeNode.text(`${value} ${units}`);
      }
    );
  });
}
